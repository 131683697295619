<template>
    <div class="container-fluid">
   <div class="row">
      <div class="col-sm-12">
         <div class="card">
            <div class="card-body">
               <div class="table-responsive pricing pt-2">
                  <table id="my-table" class="table">
                     <thead>
                        <tr>
                           <th class="text-center prc-wrap"></th>
                           <th class="text-center prc-wrap">
                              <div class="prc-box">
                                 <div class="h3 pt-4">$19<small> / Per month</small>
                                 </div> <span class="type">Basic</span>
                              </div>
                           </th>
                           <th class="text-center prc-wrap">
                              <div class="prc-box active">
                                 <div class="h3 pt-4">$39<small> / Per month</small>
                                 </div> <span class="type">Standard</span>
                              </div>
                           </th>
                           <th class="text-center prc-wrap">
                              <div class="prc-box">
                                 <div class="h3 pt-4">$119<small> / Per month</small>
                                 </div> <span class="type">Platinum</span>
                              </div>
                           </th>
                           <th class="text-center prc-wrap">
                              <div class="prc-box">
                                 <div class="h3 pt-4">$219<small> / Per month</small>
                                 </div> <span class="type">Premium</span>
                              </div>
                           </th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                           <th class="text-center" scope="row">Feature 1</th>
                           <td class="text-center child-cell">
                              <i class="ri-check-line ri-2x">
                                 <svg xmlns="http://www.w3.org/2000/svg" class="text-primary" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                 </svg>
                              </i>
                           </td>
                           <td class="text-center child-cell active">
                              <i class="ri-check-line ri-2x">
                                 <svg xmlns="http://www.w3.org/2000/svg" class="text-primary" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                 </svg>
                              </i>
                           </td>
                           <td class="text-center child-cell">
                              <i class="ri-check-line ri-2x">
                                 <svg xmlns="http://www.w3.org/2000/svg" class="text-primary" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                 </svg>
                              </i>
                           </td>
                           <td class="text-center child-cell">
                              <i class="ri-check-line ri-2x">
                                 <svg xmlns="http://www.w3.org/2000/svg" class="text-primary" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                 </svg>
                              </i>
                           </td>
                        </tr>
                        <tr>
                           <th class="text-center" scope="row">Feature 2</th>
                           <td class="text-center child-cell">
                              <i class="ri-close-line i_close">
                                 <svg xmlns="http://www.w3.org/2000/svg" class="text-primary" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                 </svg>
                              </i>
                           </td>
                           <td class="text-center child-cell active">
                              <i class="ri-check-line ri-2x">
                                 <svg xmlns="http://www.w3.org/2000/svg" class="text-primary" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                 </svg>
                              </i>
                           </td>
                           <td class="text-center child-cell">
                              <i class="ri-check-line ri-2x">
                                 <svg xmlns="http://www.w3.org/2000/svg" class="text-primary" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                 </svg>
                              </i>
                           </td>
                           <td class="text-center child-cell">
                              <i class="ri-check-line ri-2x">
                                 <svg xmlns="http://www.w3.org/2000/svg" class="text-primary" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                 </svg>
                              </i>
                           </td>
                        </tr>
                        <tr>
                           <th class="text-center" scope="row">Feature 3</th>
                           <td class="text-center child-cell">
                              <i class="ri-close-line i_close">
                                 <svg xmlns="http://www.w3.org/2000/svg" class="text-primary" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                 </svg>
                              </i>
                           </td>
                           <td class="text-center child-cell active">
                              <i class="ri-check-line ri-2x">
                                 <svg xmlns="http://www.w3.org/2000/svg" class="text-primary" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                 </svg>
                              </i>
                           </td>
                           <td class="text-center child-cell">
                              <i class="ri-check-line ri-2x">
                                 <svg xmlns="http://www.w3.org/2000/svg" class="text-primary" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                 </svg>
                              </i>
                           </td>
                           <td class="text-center child-cell">
                              <i class="ri-check-line ri-2x">
                                 <svg xmlns="http://www.w3.org/2000/svg" class="text-primary" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                 </svg> 
                              </i>
                           </td>
                        </tr>
                        <tr>
                           <th class="text-center" scope="row">Feature 4</th>
                           <td class="text-center child-cell">
                              <i class="ri-check-line ri-2x">
                                 <svg xmlns="http://www.w3.org/2000/svg" class="text-primary" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                 </svg>
                              </i>
                           </td>
                           <td class="text-center child-cell active">
                              <i class="ri-check-line ri-2x">
                                 <svg xmlns="http://www.w3.org/2000/svg" class="text-primary" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                 </svg>
                              </i>
                           </td>
                           <td class="text-center child-cell">
                              <i class="ri-check-line ri-2x">
                                 <svg xmlns="http://www.w3.org/2000/svg" class="text-primary" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                 </svg>
                              </i>
                           </td>
                           <td class="text-center child-cell">
                              <i class="ri-check-line ri-2x">
                                 <svg xmlns="http://www.w3.org/2000/svg" class="text-primary" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                 </svg>
                              </i>
                           </td>
                        </tr>
                        <tr>
                           <th class="text-center" scope="row">Feature 5</th>
                           <td class="text-center child-cell">
                              <i class="ri-close-line i_close">
                                 <svg xmlns="http://www.w3.org/2000/svg" class="text-primary" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                 </svg>
                              </i>
                           </td>
                           <td class="text-center child-cell active">
                              <i class="ri-close-line i_close">
                                 <svg xmlns="http://www.w3.org/2000/svg" class="text-primary" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                 </svg>
                              </i>
                           </td>
                           <td class="text-center child-cell">
                              <i class="ri-check-line ri-2x">
                                 <svg xmlns="http://www.w3.org/2000/svg" class="text-primary" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                 </svg>
                              </i>
                           </td>
                           <td class="text-center child-cell">
                              <i class="ri-check-line ri-2x">
                                 <svg xmlns="http://www.w3.org/2000/svg" class="text-primary" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                 </svg>
                              </i>
                           </td>
                        </tr>
                        <tr>
                           <td class="text-center">
                              <i class="ri-close-line i_close">
                                 <svg xmlns="http://www.w3.org/2000/svg" class="text-primary" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                 </svg>
                              </i>
                           </td>
                           <td class="text-center"> <a href="#" class="btn btn-primary mt-3">Purchase</a>
                           </td>
                           <td class="text-center"> <a href="#" class="btn btn-primary mt-3">Purchase</a>
                           </td>
                           <td class="text-center"> <a href="#" class="btn btn-primary mt-3">Purchase</a>
                           </td>
                           <td class="text-center"> <a href="#" class="btn btn-primary mt-3">Purchase</a>
                           </td>
                        </tr>
                     </tbody>
                  </table>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
</template>
<script>
export default {
    name:'Pricing1'
}
</script>